@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 206 100% 100%;
    --foreground: 206 5% 10%;
    --card: 206 50% 100%;
    --card-foreground: 206 5% 15%;
    --popover: 206 100% 100%;
    --popover-foreground: 206 100% 10%;
    --primary: 206 60% 40%;
    --primary-foreground: 0 0% 100%;
    --secondary: 206 30% 90%;
    --secondary-foreground: 0 0% 0%;
    --muted: 168 30% 95%;
    --muted-foreground: 206 5% 40%;
    --accent: 220 14% 96%;
    --accent-foreground: 206 5% 15%;
    --destructive: 0 100% 50%;
    --destructive-foreground: 206 5% 100%;
    --border: 0 0% 90%;
    --input: 216 12% 84%;
    --ring: 206 60% 40%;
    --radius: 0.5rem;
  }
  .dark {
    --background: 206 50% 10%;
    --foreground: 206 5% 100%;
    --card: 206 50% 10%;
    --card-foreground: 206 5% 100%;
    --popover: 206 50% 5%;
    --popover-foreground: 206 5% 100%;
    --primary: 206 59.8% 40%;
    --primary-foreground: 0 0% 100%;
    --secondary: 206 30% 20%;
    --secondary-foreground: 0 0% 100%;
    --muted: 168 30% 25%;
    --muted-foreground: 206 5% 65%;
    --accent: 168 30% 25%;
    --accent-foreground: 206 5% 95%;
    --destructive: 0 100% 50%;
    --destructive-foreground: 206 5% 100%;
    --border: 206 30% 50%;
    --input: 206 30% 50%;
    --ring: 206 59.8% 40%;
    --radius: 0.5rem;
  }
}
/* 17°, 91%, 50% */
@layer base {
  /* * {
    @apply border-border;
  } */
  body {
    @apply bg-background text-foreground;
  }
}

#lesson p {
  max-width: 700px;
}

@layer utilities {
  * {
    @apply outline-slate-300;
  }

  /* input {
    @apply border-none outline-slate-300;
  } */

  .btn {
    @apply font-semibold rounded-md whitespace-nowrap;
  }

  .btn-blue {
    @apply text-white bg-blue-500 enabled:hover:bg-blue-600 enabled:active:bg-blue-700;
  }

  .btn-altblue {
    @apply text-white bg-altblue-500 enabled:hover:bg-altblue-600 enabled:active:bg-altblue-600;
  }

  .btn-red {
    @apply text-white bg-red-500 enabled:hover:bg-red-600 enabled:active:bg-red-700;
  }

  .btn-green {
    @apply text-white bg-green-500 enabled:hover:bg-green-600 enabled:active:bg-green-700;
  }

  .btn-goast {
    @apply enabled:hover:bg-gray-100 enabled:active:bg-gray-200;
  }

  .btn-outline {
    @apply bg-white hover:bg-gray-100 active:bg-gray-200;
    box-shadow: inset 0px 0px 0px 1px #cbd5e0;
  }

  .btn-outline-beige {
    @apply bg-beige hover:opacity-70;
    box-shadow: inset 0px 0px 0px 1px #cbd5e0;
  }

  .btn-icon {
    @apply inline-flex items-center justify-center;
  }

  .btn-icon-sm {
    @apply text-xs w-7 h-7;
  }

  .btn-icon-md {
    @apply w-10 h-10 text-base;
  }

  .btn-icon-lg {
    @apply w-12 h-12 text-lg;
  }

  .btn-altblue {
    @apply text-white bg-altblue-500;
  }

  .btn-disabled {
    @apply opacity-50 cursor-default;
  }

  .avatar-online {
    @apply absolute bottom-0 right-0 flex items-center justify-center rounded-full;
    transform: translate(25%, 25%);
    width: 1.15em;
    height: 1.15em;
    border: 0.18em solid white;
  }

  .badge {
    @apply absolute right-0 top-0 flex items-center justify-center rounded-full bg-red-500 text-white transform translate-x-1.5 -translate-y-1.5 text-xs pointer-events-none select-none;
    width: 1.6em;
    height: 1.6em;
  }

  /* .loader {
    border-top-color: #3498db;
    -webkit-animation: spinner 1s linear infinite;
    animation: spinner 1s linear infinite;
  } */

  .mx-7px {
    margin-right: 7px;
    margin-left: 7px;
  }

  .shadow-custom {
    box-shadow: inset 0 0 0px #e5e5e5;
  }

  .shadow-box {
    box-shadow: 1px 1px 5px #e2e2e2;
  }

  /* .shadow-box2 {
    box-shadow: 1px 1px 7px #e5e5e5;
  } */

  .bar-anim {
    transition: width 1000ms ease-in-out;
  }

  /* .playing {
    box-shadow: 0 0 0 2px #fff, 0 0 0 4px #dd8835;
  } */

  /* .h-nav {
    height: 68px;
  } */

  .btn-fixed-w {
    @apply w-40 m-1 text-base h-11 whitespace-nowrap hover:opacity-70 active:opacity-50;
  }

  .btn-xs {
    @apply px-2 py-1 text-xs;
  }

  .btn-sm {
    @apply px-3 py-1.5 text-sm;
  }

  .btn-base {
    @apply px-4 py-2 text-base;
  }

  .h-70px {
    height: 70px;
  }

  .h-100px {
    height: 100px;
  }

  .logo-sm {
    width: 89px;
    height: 40px;
    background: url("/images/logo-retina.png");
    background-size: cover;
  }

  .logo {
    width: 125px;
    height: 56px;
    background: url("/images/logo-retina.png");
    background-size: cover;
  }

  .crown {
    width: 34px;
    height: 29px;
    background: url("/images/logo-crown.png");
    background-size: cover;
  }

  .board-shadow {
    box-shadow: 1px 1px 7px #e5e5e5;
  }

  .dropdown {
    @apply absolute bottom-0 z-50 min-w-full p-1 origin-top-right bg-white rounded-md outline-none;
    /* transform: translate(0, calc(100% + 32px)); */
    left: 40%;
    transform: translate(calc(-50% + 5px), calc(100% + 8px));
    cursor: default;
    margin: 0 !important;
    box-shadow: 0px 0px 5px #e2e2e2;
  }

  .dropdown:hover {
    opacity: 100 !important;
  }

  .dropdown-btn {
    width: 100%;
    height: 100%;
    padding: 0.5rem 1rem;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .f-content {
    min-width: fit-content;
  }

  .no-padding {
    padding: 0 !important;
  }

  .divider {
    padding: 0.4rem 0.2rem !important;
  }

  .hidden-overlay {
    position: fixed;
    inset: -100%;
    z-index: 10;
    pointer-events: auto;
  }

  /* .segmented-control {
    overflow: hidden;
    cursor: pointer;
  } */

  .search-result {
    @apply absolute flex flex-col justify-between w-full overflow-hidden translate-y-full bg-white border-t-2 rounded-b-lg shadow -bottom-0;
  }

  .search-result-row {
    @apply flex items-center p-3 space-x-2 font-semibold border-b-2 hover:bg-slate-100 last:border-b-0 border-slate-100;
  }

  .search-result-row-selected {
    @apply search-result-row bg-altgray-100;
    cursor: default;
  }

  .change-btn {
    @apply p-2 text-white bg-altblue-500;
    margin: 0 0 0 auto !important;
    cursor: pointer;
  }

  .bg-david {
    background-color: aqua;
  }
}

@layer base {
  /* open-sans-cyrillic-300-normal*/
  @font-face {
    font-family: "Open Sans";
    font-style: normal;
    font-display: swap;
    font-weight: 300;
    src: url("/fonts/open-sans-cyrillic-300-normal.woff2") format("woff2"),
      url("/fonts/open-sans-cyrillic-300-normal.woff") format("woff");
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
  }

  /* open-sans-latin-300-normal*/
  @font-face {
    font-family: "Open Sans";
    font-style: normal;
    font-display: swap;
    font-weight: 300;
    src: url("/fonts/open-sans-latin-300-normal.woff2") format("woff2"),
      url("/fonts/open-sans-latin-300-normal.woff") format("woff");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
      U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
      U+2215, U+FEFF, U+FFFD;
  }

  /* open-sans-cyrillic-400-normal*/
  @font-face {
    font-family: "Open Sans";
    font-style: normal;
    font-display: swap;
    font-weight: 400;
    src: url("/fonts/open-sans-cyrillic-400-normal.woff2") format("woff2"),
      url("/fonts/open-sans-cyrillic-400-normal.woff") format("woff");
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
  }

  /* open-sans-latin-400-normal*/
  @font-face {
    font-family: "Open Sans";
    font-style: normal;
    font-display: swap;
    font-weight: 400;
    src: url("/fonts/open-sans-latin-400-normal.woff2") format("woff2"),
      url("/fonts/open-sans-latin-400-normal.woff") format("woff");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
      U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
      U+2215, U+FEFF, U+FFFD;
  }

  /* open-sans-cyrillic-600-normal*/
  @font-face {
    font-family: "Open Sans";
    font-style: normal;
    font-display: swap;
    font-weight: 600;
    src: url("/fonts/open-sans-cyrillic-600-normal.woff2") format("woff2"),
      url("/fonts/open-sans-cyrillic-600-normal.woff") format("woff");
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
  }

  /* open-sans-latin-600-normal*/
  @font-face {
    font-family: "Open Sans";
    font-style: normal;
    font-display: swap;
    font-weight: 600;
    src: url("/fonts/open-sans-latin-600-normal.woff2") format("woff2"),
      url("/fonts/open-sans-latin-600-normal.woff") format("woff");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
      U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
      U+2215, U+FEFF, U+FFFD;
  }

  /* open-sans-cyrillic-700-normal*/
  @font-face {
    font-family: "Open Sans";
    font-style: normal;
    font-display: swap;
    font-weight: 700;
    src: url("/fonts/open-sans-cyrillic-700-normal.woff2") format("woff2"),
      url("/fonts/open-sans-cyrillic-700-normal.woff") format("woff");
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
  }

  /* open-sans-latin-700-normal*/
  @font-face {
    font-family: "Open Sans";
    font-style: normal;
    font-display: swap;
    font-weight: 700;
    src: url("/fonts/open-sans-latin-700-normal.woff2") format("woff2"),
      url("/fonts/open-sans-latin-700-normal.woff") format("woff");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
      U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
      U+2215, U+FEFF, U+FFFD;
  }

  html {
    scroll-behavior: smooth;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    /* Adjust the selector as needed */
    scroll-margin-top: 200px; /* Adjust the value to your needs */
  }

  body {
    font-family: "Open Sans", sans-serif;
    font-size: 16px;
    color: #585858;
    -webkit-font-smoothing: auto;
    /* background-color: #f0f1f3; */
    @apply bg-athens-gray-100;
  }

  a:hover {
    @apply text-orange-400;
  }

  #__next {
    height: 100vh;
    display: flex;
    flex-direction: column;
  }

  /* h1 {
    text-align: center;
    font-size: 300%;
    font-weight: bolder;
    margin: 0;
    padding: 0;
  }

  h2 {
    font-size: 200%;
    font-weight: 600;
  }

  h3 {
    font-size: 150%;
    font-weight: 600;
  }

  h4 {
    font-size: 150%;
    font-weight: lighter;
  } */

  select {
    /* make arrow and background */
    width: 100%;
    padding: 5px 10px 5px 10px;
    font-size: 16px;
    border: 1px solid #e6e6e6;
    border-radius: 3px;
    cursor: pointer;

    height: 38px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: url(/images/chevron.png) no-repeat #f6f6f6;
    background-size: 11px 7px;
    background-position: center right;
    background-origin: content-box;
    background-color: white;
  }

  input:focus {
    outline: none !important;
    @apply border border-gray-300;
  }

  .tooltip {
    @apply invisible opacity-0 absolute z-0 whitespace-nowrap px-3 py-1.5 rounded-md  bg-gray-800 text-gray-200 text-xs -bottom-8;
  }

  .has-tooltip {
    @apply relative flex flex-col items-center;
  }

  .has-tooltip:hover .tooltip {
    @apply z-10 invisible opacity-100 md:visible;
    transition: opacity 100ms;
  }

  .piece {
    background-size: 100%;
    position: absolute;
    cursor: pointer;
    width: 12.5%;
    height: 12.5%;
    user-select: none;
    z-index: 20;
    will-change: transform;
  }
}
