.lobbyTable {
  width: 100%;
  border-collapse: collapse;
}

.lobbyTable td,
.lobbyTable th {
  text-align: left;
  white-space: nowrap;
  text-overflow: clip;
  overflow: hidden;
  font-size: 80%;
  padding: 10px 15px;
}

.lobbyTable th {
  background-color: #fff;
  cursor: auto;
  color: texts;
  font-weight: 400;
}
.lobbyTable tr {
  border-bottom: 1px solid lines;
  background-color: #f6f6f6;
  color: texts;
}
